import React from "react";

import { PageContent } from "../PageContent";

export const Projects = () => {
  return (
    <PageContent>
      <h1>projects</h1>
      <p>
        Most of my unclaimed time goes to my two kids, there isn't much time
        for other projects. Someday this side project section will have more
        completed code in it, but for now it is just this.
      </p>
      <a href="http://www.brannonheftel.com/browser-synth/">browser synth experiment</a>
    </PageContent>
  );
};
